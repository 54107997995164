import { SnackBar} from './CommonActions'

export function clearCartItems() {return { type: 'CLEAR_CART_ITEMS'}}

export function CartAddItem(data){    
    return function(dispatch) {
        dispatch(CartAddItemAction(data));
        dispatch(SnackBar(true,'Item added to your cart','info'));
    }
          
}

export function CartAddItemAction(data){
    data['booking_object'] = CreateBookingObject(data);
    return {
        type: 'ADD_ITEM',
        item:data,        
    }
}

export function BookNow(status) {
    return {
        type: 'BOOK_NOW',
        bookNow:status
    }
}

export function storeCartItems(results) {
    return {
        type: 'STORE_CART_ITEMS',
        list:results.data.items,
        total:results.data.total,
        total:results.data.total,
        totalFormatted:results.data.total_formatted,
                 
    }
}

export function CartRemoveItem(ItemIndex){
    return {
        type: 'REMOVE_ITEM',
        index:ItemIndex
    }
      
}

export function GetCartTotal(items){    
    return items.reduce((total,item)=>item.amount + total,0);
}

export function CreateBookingObject(cartItem){

        var numTravelers =  cartItem.adults+ cartItem.children+ cartItem.infants;
        var travelers = [];


        for(var x=0; x < numTravelers; x++){
            travelers.push({
                firstname:"",
                lastname:"",
                email:"",
                phone:"",
                lead_traveller: x === 0 ? 1:0 
            })    
        }


        return {
                    start_date  : cartItem.from_date,
                    activity_id : cartItem.activity.id,
                    num_adults : cartItem.adults,
                    num_children : cartItem.children,
                    num_infants : cartItem.infants,
                    remarks : null ,
                    booking_amount : cartItem.amount,
                    pickup_location : 'hotel',
                    hotel_name: "",
                    hotel_address : "",
                    flight_no : "",
                    flight_time : "" ,
                    contact_firstname : "" ,
                    contact_lastname : "",
                    contact_email : "",
                    contact_phone : "",
                    traveller : travelers,
               }
}